import React, { useEffect, useState } from 'react';
import style from './UserManagement.module.scss';
import Demo_Image from '../assets/UserManagement/Demo_Image_Icon.svg';
import Arrow_icon from '../assets/UserManagement/Arrow_icon.svg';
import lineSolid from '../assets/UserManagement/line_icon.svg';
import hamburger from '../assets/UserManagement/hamburger.svg';
import { ReactComponent as InfoIcon } from '../assets/UserManagement/Info_grey_icon.svg';
import { ReactComponent as UploadIcon } from '../assets/UserManagement/Upload_Icon.svg';
import { ReactComponent as AddIcon } from '../assets/UserManagement/white_Plus_icon.svg';
import { ReactComponent as WarningIcon } from '../assets/UserManagement/warning_icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/UserManagement/delete_white_icon.svg';
import calenderIcon from '../assets/UserManagement/calender_icon.svg';
import DropDownArrow from '../../Components/assets/loginsettings/dropDownArrow.svg';
import { ReactComponent as DownloadIcon } from '../assets/UserManagement/download_icon.svg';
import clsx from 'clsx';
import { ConfigProvider, DatePicker, Input, Table, Tooltip } from 'antd';
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import CustomDropDownForSelect from '../../Ui/DropDown/CustomDropDown';
import dayjs from 'dayjs';
import { TableColumns } from './Const';
import CommonModal from '../../utills/CommonModal/CommonModal';
import CurrentDateTime from './CurrentDateTime';
import { creatUser, deleteUser, fetchUsers, getSubscription, updateUser } from '../../api/api';
import { useSelector } from 'react-redux';

const UserManagement = () => {
  const [tableData, setTableData] = useState([]);
  const toggleData = useSelector((state) => state.loginSetting);
  const [isModalVisible, setIsModalVisible] = useState({
    addModal: false,
    deleteModal: false,
  });
  const [logout, setLogout] = useState(false);
  const [showEditDelete, setShowEditDelete] = useState({
    show: false,
    id: 0,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isRevoked,setIsRevoked] = useState(false);
  const [licenseCount, setLicenseCount] = useState({
    name:'',
    totalLicenses: 0,
    usedLicenses: 0,
    licensesLeft: 0,
  });
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [addNewUser, setAddNewUser] = useState({
    name: '',
    email: '',
    subscriptionId: '',
  }); 
  

  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleDeleteUser = async () => {
    let response;
    try {
      if(isRevoked){
        response = await updateUser(selectedUserId,addNewUser)
        setIsModalVisible({ ...isModalVisible, deleteModal: false });
        setIsRevoked(false)
      }else{
        response = await deleteUser(selectedUserId); 
        setIsModalVisible({ ...isModalVisible, deleteModal: false }); 
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  const setEditUser = (user) => {
    const newStatus = user.status === 'Access Revoked' ? 'Active' : 'Access Revoked';
    const updatedUser = {
      name: user.name,
      email: user.email,
      subscriptionId: user.subscriptionId,
    };
      updatedUser.status = newStatus;
    setAddNewUser(updatedUser);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddNewUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDropdownChange = (value) => {
    setAddNewUser((prevData) => ({
      ...prevData,
      subscriptionId: value,
    }));
  };
  const handleSaveChanges = async () => {
    let response;
    try {
      const payload = { ...addNewUser };
      delete payload.status;
      if (isEditMode) {
        response = await updateUser(selectedUserId,payload)
        setIsModalVisible({ ...isModalVisible, addModal: false });
        setIsEditMode(false)
      } else {
        response = await creatUser(addNewUser);
        setIsModalVisible({ ...isModalVisible, addModal: false });
      }
      setAddNewUser({
        name: '',
        email: '',
        subscriptionId: '',
      });
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const RetailSubscription = async () => {
    const responseData = await fetchUsers();
    const licenseUsers = responseData.licenses;
    setLicenseCount({
      name:responseData.name,
      totalLicenses: responseData.totalLicenses,
      usedLicenses: responseData.usedLicenses,
      licensesLeft: responseData.licensesLeft,
    });

    const pushTableData = [];
    for (let i = 0; i < licenseUsers.length; i++) {
      pushTableData.push({
        userName: <div className={style['table-title']}>{licenseUsers[i].name}</div>,
        email: <div className={style['table-value']}>{licenseUsers[i].email}</div>,
        planName: <div className={style['table-value']}>{licenseUsers[i].subscriptionPlan}</div>,
        subAddedOn: <div className={style['table-value']}>{licenseUsers[i].createdAt}</div>,
        status: (
          <div
            className={style['status']}
            style={
              licenseUsers[i].status === 'Pending'
                ? { color: '#D39C1D', border: '0.5px solid #D39C1D', backgroundColor: '#FBF5E8' }
                : licenseUsers[i].status === 'Access Revoked' || licenseUsers[i].status === 'Account Deleted'
                ? { color: '#D62525', border: '0.5px solid #D62525', backgroundColor: '#FDF4F4' }
                : {}
            }
          >
            {licenseUsers[i].status}
          </div>
        ),
        action: (
          <div className={style['wrap-img-menu']}>
            {showEditDelete?.show && showEditDelete?.id === i + 1 && (
              <div className={style['edit-delete-update-wrap']}>
                <div
                  className={style['text']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, addModal: true });
                    setIsEditMode(prev => prev = true);
                    setEditUser(licenseUsers[i]);
                    setSelectedUserId(licenseUsers[i].userAccount);
                  }}
                >
                  Edit
                  <CaretRightOutlined className={style['arrow']} rev={undefined} />
                </div>
                <div
                  className={style['red-text']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, deleteModal: true });
                    setEditUser(licenseUsers[i]);
                    setSelectedUserId(licenseUsers[i].userAccount);
                    setIsRevoked(true)
                  }}
                >
                  {licenseUsers[i].status === 'Access Revoked' ? 'Reactivate' : 'Revoke'}
                </div>
                <div
                  className={style['red-text']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, deleteModal: true });
                    setSelectedUserId(licenseUsers[i].userAccount);
                  }}
                >
                  Delete
                </div>
              </div>
            )}
            <div
              className={style['edit-delete-update-img']}
              onClick={() => {
                setShowEditDelete({
                  show: true,
                  id: i + 1,
                });
              }}
            >
              <img src={hamburger} alt="is edit" />
            </div>
          </div>
        ),
        key: i,
      });
    }
    setTableData(pushTableData);
  };
  useEffect(() => {
    RetailSubscription();
  }, [showEditDelete, isModalVisible]);
  const LastDaysFilter = [
    { value: 7, label: 'Last 7 days' },
    { value: 15, label: 'Last 15 days' },
    { value: 30, label: 'Last 30 days' },
    { value: 60, label: 'Last 60 days' },
    { value: 90, label: 'Last 90 days' },
  ];
  const formatDate = (date) => {
    if (!date) return '';
    const day = date.date();
    const suffix =
      day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
        ? 'rd'
        : 'th';
    return `${day}${suffix} ${date.format('MMM, YYYY')}`;
  };
  const getSubscriptionData = async () => {
    const response = await getSubscription();
    setSubscriptionPlans(response);
  };
  useEffect(() => {
    getSubscriptionData();
  }, []);
  return (
    <>
      {/* Add and Edit Modal for User Management */}
      <CommonModal
        width={600}
        visible={isModalVisible?.addModal}
        isModalVisible={() => {
          setIsModalVisible({ ...isModalVisible, addModal: false });
          setShowEditDelete({
            show: false,
            id: 0,
          });
        }}
      >
        <div className={style['modal_Container']}>
          <div className={style['modal_title']}>Add New User</div>
          <div className={style['modal_description']}>
            Tailor subscription plans for your corporate clients effortlessly
          </div>
          <div className={style['from-field']} style={{ marginTop: '36px' }}>
            <div className={style['name']}>Name</div>
            <div className={style['input-style']}>
              <Input placeholder="Enter Name" name="name" value={addNewUser.name} onChange={handleInputChange} />
            </div>
          </div>
          <div className={style['from-field']} style={{ marginTop: '16px' }}>
            <div className={style['name']}>Email Id</div>
            <div className={style['input-style']}>
              <Input placeholder="Enter Email Id" name="email" value={addNewUser.email} onChange={handleInputChange} />
            </div>
          </div>
          <div className={style['from-field']} style={{ marginTop: '16px' }}>
            <div className={style['name']}>Subscription Plan</div>
            <div className={style['input-style']}>
              <CustomDropDownForSelect
                placeholder="Select"
                options={{ array: subscriptionPlans, key: '_id' }}
                label={{ array: subscriptionPlans, key: 'name' }}
                defaultValueForSelectOption={addNewUser.subscriptionId}
                onChangeForSelect={handleDropdownChange}
                customStyle={{
                  height: '40px',
                  border: '1px solid #d2d2d2',
                }}
                hoverColor="#E6E6E6"
                dropdownStyle={{ zIndex: 1050 }}
              />
              <CustomDropDownForSelect
                placeholder="Select"
                options={{ array: subscriptionPlans, key: '_id' }}
                label={{ array: subscriptionPlans, key: 'name' }}
                customStyle={{
                  height: '40px',
                  border: '1px solid #d2d2d2',
                  marginTop: '16px',
                }}
                hoverColor="#E6E6E6"
                dropdownStyle={{ zIndex: 1050 }}
              />
            </div>
          </div>
          <div className={style['btns-container']}>
            <button className={style['save-changes']} onClick={handleSaveChanges}>
              Save Changes
            </button>
          </div>
        </div>
      </CommonModal>
      {/* Delete Modal for User Management */}
      <CommonModal
        width={600}
        visible={isModalVisible?.deleteModal}
        isModalVisible={() => {
          setIsModalVisible({ ...isModalVisible, deleteModal: false });
          setShowEditDelete({
            show: false,
            id: 0,
          });
        }}
      >
        <div className={style['delete_modal_Container']}>
          <WarningIcon />
          <div className={style['delete_modal_title']}>Are you sure you want to {isRevoked ? 'access revoked': 'delete'} this user? </div>
          <div className={style['delete_modal_description']}>
            {isRevoked ? 'Access Revoking':'Deleting'} this user will also remove their active subscriptions.
            <br /> This action cannot be undone.
          </div>
          <button className={style['delete-btn']} onClick={handleDeleteUser}>
            <DeleteIcon />
            Yes, {isRevoked ? 'Access Revoked' : 'Delete User'}
          </button>
          <button className={style['goback-btn']}>Go back</button>
        </div>
      </CommonModal>
      <div className={style['main-container']}>
        <div className={style['header-logo']}>
          <img className={style['image-logo']} src={toggleData?.brandLogoUrl || Demo_Image} alt="Demo_Image" />
        </div>
        <div className={clsx(style['flex-Justify-between'], style['container'])}>
          <div className={style['header-title']}>User management Dashboard</div>
          <div style={{ position: 'relative' }}>
            <div className={style['profile-container']} onClick={() => setLogout(!logout)}>
              <div className={style['profile-name']}>RC</div>
              <img src={Arrow_icon} alt="Arrow_icon" style={{ rotate: logout ? '0deg' : '180deg' }} />
            </div>
            {logout && <div className={style['logout']}>Logout</div>}
          </div>
        </div>
        <div className={style['body-container']}>
          <div className={clsx(style['flex-Justify-between'], style['containe-2'])}>
            <div className={style['welcome-back']}>
              Welcome back, <span>{licenseCount.name}</span>
            </div>
            {/* <div>13 January, 2024 11:23AM</div> */}
            <CurrentDateTime />
          </div>
          <div className={clsx(style['flex-Justify-between'])} style={{ marginTop: '25px' }}>
            <div className={style['licenses-box']}>
              <div className={style['licenses-title']}>Total licenses</div>
              <div className={style['number-licenses']}>{licenseCount.totalLicenses}</div>
            </div>
            <div className={style['licenses-box']}>
              <div className={style['licenses-title']}>Licenses Used</div>
              <div className={style['number-licenses']}>{licenseCount.usedLicenses}</div>
            </div>
            <div className={style['licenses-box']}>
              <div className={style['licenses-title']}>Licenses Left</div>
              <div className={style['number-licenses']}>{licenseCount.licensesLeft}</div>
            </div>
          </div>
          <div className={clsx(style['flex-Justify-between'])} style={{ marginTop: '20px' }}>
            <div>
              <div className={style['search-box']}>
                <Input placeholder="Search By Name" />
                <SearchOutlined rev={undefined} />
              </div>
              <div></div>
            </div>
            <div className={style['flex-center']} style={{ gap: '10px' }}>
              <CustomDropDownForSelect
                placeholder="Select"
                options={{ array: LastDaysFilter, key: 'value' }}
                label={{ array: LastDaysFilter, key: 'label' }}
                customStyle={{
                  width: '135px',
                  height: '40px',
                  border: '1px solid #d2d2d2',
                  backgroundColor: '#F2F2F2',
                  marginLeft: '30px',
                }}
                hoverColor="#E6E6E6"
              />
              <div className={style['download-icon']}>
                <DownloadIcon />
                <span>Export CSV</span>
              </div>
              <div className={style['range-container']}>
                <div className={style['date-picker-wrap']}>
                  <div className={style['divider']}>
                    <img src={calenderIcon} alt="calenderIcon" />
                  </div>
                  <DatePicker
                    placeholder="Start Date"
                    value={dayjs()}
                    suffixIcon={null}
                    format={formatDate}
                    disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                    popupStyle={{ fontSize: '11px', zIndex: 2000 }}
                  />
                  <div className={style['divider']}>
                    <div className={style['arrow-img-wrapper']}>
                      <img src={lineSolid} alt="line-solid" />
                    </div>
                  </div>
                  <DatePicker
                    placeholder="End Date"
                    value={dayjs()}
                    suffixIcon={null}
                    format={formatDate}
                    disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                    popupStyle={{ fontSize: '11px', zIndex: 2000 }}
                  />
                  <div className={style['divider']}>
                    <div className={style['arrow-img-wrapper']}>
                      <img src={DropDownArrow} alt="arrow_down" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style['table-container']}>
            <div className={style['container-space-between']}>
              <div className={style['flex-center']} style={{ gap: '5px' }}>
                <div className={style['active-user-title']}>User Details</div>
                <Tooltip color="#1D2943" placement="right" title={<span style={{ fontSize: '12px' }}>TBD</span>}>
                  <InfoIcon />
                </Tooltip>
              </div>
              <div className={style['flex-center']} style={{ gap: '20px' }}>
                <div className={style['imageUploader']}>
                  <input type="file" accept="image/png, image/jpeg" />
                  <div className={style['upload_Icon']}>
                    <UploadIcon />
                    <div>Bulk Upload Users</div>
                  </div>
                </div>
                <div
                  className={style['add-new-user']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, addModal: true });
                    setAddNewUser({
                      name: '',
                      email: '',
                      subscriptionId: '',
                    });
                  }}
                >
                  <AddIcon />
                  <div>Add New User</div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '14px' }}>
              <div className={style['table-Container']}>
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerSplitColor: 'transparent',
                      },
                    },
                  }}
                >
                  <Table
                    dataSource={tableData}
                    columns={TableColumns}
                    pagination={{
                      position: ['bottomRight'],
                      showSizeChanger: false,
                      pageSize: 10,
                    }}
                    scroll={{ x: 'max-content' }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
