export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const ValidateMobileNumber = (phoneNubmer) => {
  const re = /^[6-9][0-9]{9}$/;
  return re.test(phoneNubmer);
};
export function loginHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.loginHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler', value);
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.loginHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter
  try {
    // @ts-ignore
    window?.LOGINCHALLENGE?.postMessage(value);
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('LOGIN', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}

export function googleLogInHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.googleLogInClickHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.googleLogInClick.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('GOOGLE_LOGIN_CLICK', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}
export function appleLogInHandlerForMobile(value) {
 // for iOS
 try {
  // @ts-ignore
  window.webkit.messageHandlers.appleLogInClick.postMessage(value);
 } catch (error) {
  console.warn('Did not find iOS redirect handler');
 }
 // for Flutter-2-package
 try {
  // @ts-ignore
  window.flutter_inappwebview.callHandler('APPLE_LOGIN_CLICK', value);
 } catch (error) {
  console.warn('Did not find Flutter 2 handler');
 }
 // for React-Native
 try {
  // @ts-ignore
  window.ReactNativeWebView.postMessage(value);
 } catch (error) {
  console.warn('Did not find ReactNativeWebView handler');
 }
}

export function facebookLogInHandlerForMobile(value) {
  // for iOS
  try {
   // @ts-ignore
   window.webkit.messageHandlers.facebookLogInClick.postMessage(value);
  } catch (error) {
   console.warn('Did not find iOS redirect handler');
  }
  // for Flutter-2-package
  try {
   // @ts-ignore
   window.flutter_inappwebview.callHandler('FACKBOOK_LOGIN_CLICK', value);
  } catch (error) {
   console.warn('Did not find Flutter 2 handler');
  }
  // for React-Native
  try {
   // @ts-ignore
   window.ReactNativeWebView.postMessage(value);
  } catch (error) {
   console.warn('Did not find ReactNativeWebView handler');
  }
 }
export const validate = (item, rules) => {
  if (rules.minLength && item.length < rules.minLength) return false;
  if (rules.maxLength && item.length > rules.maxLength) return false;
  if (rules.pattern && !rules.pattern.test(item)) return false;
  return true;
};
export const checkSuccessCode = (response) => {
  return Math.floor(response.status / 100) === 2;
};

export const matchStatusCode = (response, statusCode) => {
  return response.status === statusCode ? true : false;
};

export function logOutHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.logOutHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler', value);
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.logOutHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter
  try {
    // @ts-ignore
    window?.LOGOUTHANDLER?.postMessage(value);
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('LOGOUT', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}
export function errorHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.errorHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect errorHandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.errorHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter
  try {
    // @ts-ignore
    window?.ERRORHANDLER?.postMessage(value);
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('ERROR', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}

export function slientLoginHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.slientLoginHandler(value);
    /* eslint-enable */

  } catch (error) {
    // console.log()
    console.warn('did not find android redirect slientLoginHandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.slientLoginHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect slientLoginHandler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('SLIENT_USER_LOGIN', value);
  } catch (error) {
    console.warn('did not find flutter 2 slientLoginHandler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView slientLoginHandler');
  }
}
export function userProfileUpdateHandlerForMobile(value) {

  // for android
  try {
    /* eslint-disable */
    redirectionHandler?.userProfileUpdateHandler(value);
    /* eslint-enable */

  } catch (error) {
    // console.log()
    console.warn('did not find android redirect userProfileUpdateHandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.userProfileUpdateHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect userProfileUpdateHandler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('USER_PROFILE_UPDATE', value);
  } catch (error) {
    console.warn('did not find flutter 2 userProfileUpdateHandler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView userProfileUpdateHandler');
  }
}
export function userProfileDownloadInvoiceHandlerForMobile(value) {

  // for android
  try {
    /* eslint-disable */
    redirectionHandler?.userProfileDownloadInvoiceHandler(value);
    /* eslint-enable */

  } catch (error) {
    // console.log()
    console.warn('did not find android redirect userProfileDownloadInvoiceHandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.userProfileDownloadInvoiceHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect userProfileDownloadInvoiceHandler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('USER_PROFILE_UPDATE', value);
  } catch (error) {
    console.warn('did not find flutter 2 userProfileDownloadInvoiceHandler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView userProfileDownloadInvoiceHandler');
  }
}