import React, { useEffect, useState } from 'react';
import { CSCGetAccessToken } from './utills';
import loginStyle from '../NewLogin/review-screens.module.scss';
import style from '../../Ui/DropDown/commonAntdSelectedBox.module.scss';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Profile_icon from '../assets/loginsettings/profile_icon.svg';
import Gender_icon from '../assets/loginsettings/gender_icon.svg';
import Calender_icon from '../assets/loginsettings/calender_icon.svg';
import Search_icon from '../assets/loginsettings/search_icon.svg';
import CustomDropDownForSelect from '../../Ui/DropDown/CustomDropDown';
import { ReactComponent as DropDownArrow } from '../assets/loginsettings/dropDownArrow.svg';
import { Country_flag_json } from '../common/country-flag-json';
import { DatePicker, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import {
  checkSuccessCode,
  logOutHandlerForMobile,
  userProfileUpdateHandlerForMobile,
  validateEmail,
  ValidateMobileNumber,
} from '../../utills/validator';
import dayjs from 'dayjs';
import { antdMessageError, antdMessageSuccess, antdMessageWarning } from '../common/antdMessageComponent';
import axios from 'axios';
import clsx from 'clsx';
import CTALoader from '../../utills/ctaLoader';

export function SecondaryDetails() {
  const queryParams = new URLSearchParams(window.location.search);
  const toggleData = useSelector((state) => state.loginSetting);
  const userDetails = useSelector((state) => state.userDetails);
  const [inputValue, SetInputValue] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const configuration = JSON.parse(toggleData?.uiConfig);

  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase()),
  );
  const dropdownRender = (menu) => (
    <div>
      <div className={loginStyle['dropDown-search']}>
        <Input placeholder="Search..." onChange={(e) => SetInputValue(e.target.value)} />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );

  useEffect(() => {
    if (toggleData?.secondaryFields?.enabled) {
      if (
        toggleData?.secondaryFields?.enableSecondaryInput?.value === 'MANDATORY' &&
        (!validateEmail(data?.email) || data?.secondaryPhone === '')
      ) {
        return setBtnDisabled(true);
      } else if (
        toggleData?.secondaryFields?.enableSecondaryInput?.value === 'MANDATORY' &&
        userDetails.loginType === 'PHONE' &&
        (!ValidateMobileNumber(userDetails?.phone) || data?.email === '')
      ) {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.enableNameInput?.value === 'MANDATORY' && data?.name === '') {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.dob?.value === 'MANDATORY' && data?.dateOfBirth === '') {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.gender?.value === 'MANDATORY' && data?.gender === '') {
        return setBtnDisabled(true);
      } else {
        return setBtnDisabled(false);
      }
    } else {
      return setBtnDisabled(false);
    }
  }, [data]);

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        if (toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password) {
          return;
        } else if (toggleData?.accessMethods?.password) {
          if (userDetails?.email !== '' && !validateEmail(userDetails?.email)) {
            return antdMessageWarning('Please enter a valid email address.');
          }
        } else {
          if (userDetails?.email !== '' && !validateEmail(userDetails?.email)) {
            return antdMessageWarning('Please enter a valid email address.');
          }
        }
      }
    }
  };
  const fetchData = async (Token) => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${Token}` },
      });
      if (response?.status === 200) {
        setLoading(false);
        setData(response.data);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError('It seems your account has been logged out. Please try logging in again');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
        setLoading(false);
      }
    }
  };
  const updateuserdetails = async () => {
    setLoading(true);
    const userCode = localStorage.getItem('accessToken');
    if (userCode !== null) {
      try {
        const response = await axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_API_URL}/authorization/edit-user-details`,
          headers: { Authorization: `Bearer ${userCode}` },
          data: {
            ...data,
          },
        });
        antdMessageSuccess('Updated Successfully');
        userProfileUpdateHandlerForMobile(JSON.stringify({ message: 'USER_PROFILE_UPDATE', statusCode: '200' }));
        setLoading(false);
        window.parent.postMessage({ source: 'update-from-user-thank' }, '*');
      } catch (error) {
        antdMessageError(
          error?.response?.data?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.errors?.phoneNumber ||
            error?.response?.data?.errors?.email ||
            'Error While updating user data',
        );
        setLoading(false);
        window.parent.postMessage({ source: 'update-from-user-thank' }, '*');
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
      setLoading(false);
      window.parent.postMessage({ source: 'update-from-user-thank' }, '*');
    }
  };

  useEffect(() => {
    (async () => {
      const refreshToken = queryParams.get('refreshToken');
      if (refreshToken && refreshToken !== 'null') {
        const accessToken = await CSCGetAccessToken(refreshToken);
        if (checkSuccessCode(accessToken)) return;
        localStorage.setItem('accessToken', accessToken);
        await fetchData(accessToken);
      } else {
        antdMessageWarning('It seems your account has been logged out. Please try logging in again');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    })();
  }, []);

  return (
    <div className={loginStyle['main-container']}>
      <div style={{ flexGrow: 1 }}>
        <div className={loginStyle['header-logo']}>
          <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl} alt="Demo_image" />
        </div>
        <div className={loginStyle['container']}>
          <div className={loginStyle['child-container']}>
            <div style={{ ...configuration?.userDataCollection?.formTitle, fontSize: '25px', textAlign: 'center' }}>
              Complete your User Profile
            </div>
            <div className={loginStyle['form-container']}>
              {toggleData?.secondaryFields?.enabled && (
                <>
                  {toggleData?.secondaryFields?.enabled && (
                    <div className={loginStyle['input-container']}>
                      <img src={Mobile_icon} alt="Mobile_icon" />
                      <div className={loginStyle['dropDown-container']}>
                        <Select
                          dropdownRender={dropdownRender}
                          popupMatchSelectWidth={250}
                          filterOption={false}
                          suffixIcon={<DropDownArrow />}
                          className={style['common-select-box']}
                          popupClassName={style['common-dropdown-select']}
                          value={data?.countryCode}
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '14px',
                          }}
                          onChange={(value) => {
                            data.countryCode = value;
                            setData({ ...data });
                          }}
                          optionLabelProp="label"
                          onKeyDown={keyDownHandler}
                        >
                          {filteredOptions.map((option, index) => (
                            <Select.Option
                              key={index}
                              value={option.dail_code}
                              label={option.dail_code}
                              className={style['dropdown-options']}
                            >
                              <div className={loginStyle['dropdown-flag']}>
                                <div className={loginStyle['flag-code']} style={{ fontSize: '12px' }}>
                                  {option.dail_code}
                                </div>
                                <div className={loginStyle['flag-name']}>{option.name}</div>
                                <div className={loginStyle['flag']}>{option.flag}</div>
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className={loginStyle['vertical-line']}></div>
                      <Input
                        placeholder={configuration?.userDataCollection?.secondaryLoginInput?.text || 'Phone Number'}
                        style={data?.secondaryLoginInput}
                        value={data?.phone || data?.secondaryPhone}
                        onChange={(e) => {
                          const reg = /^-?\d{1,15}$/;
                          const value = e.target.value;
                          if (reg.test(value) || value === '') {
                            data?.phoneNumber
                              ? setData({ ...data, phoneNumber: value })
                              : setData({ ...data, secondaryPhone: value });
                          }
                        }}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  )}
                  {toggleData?.secondaryFields?.enabled && (
                    <div className={loginStyle['input-container-common']}>
                      <img src={Email_icon} alt="Email_icon" />
                      <Input
                        placeholder={'Email123'}
                        value={data?.email}
                        style={{ ...data?.secondaryLoginInput }}
                        onChange={(e) => {
                          var reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          const value = e.target.value;
                          if (reg.test(value) || value === '') {
                            data.email = value;
                            setData({ ...data });
                          }
                        }}
                        onKeyDown={keyDownHandler}
                        disabled={data?.isEmailVerified}
                      />
                    </div>
                  )}
                  {toggleData?.secondaryFields?.enabled && toggleData?.secondaryFields?.enableNameInput?.isRequired && (
                    <div className={loginStyle['input-container-common']}>
                      <img src={Profile_icon} alt="Profile_icon" />
                      <Input
                        placeholder={configuration?.userDataCollection?.fullName?.text || 'Enter Your Name'}
                        style={data?.fullName}
                        onChange={(e) => {
                          data.name = e.target.value;
                          setData({ ...data });
                        }}
                        value={data?.name}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  )}
                  {toggleData?.secondaryFields?.enabled && toggleData?.secondaryFields?.dob?.isRequired && (
                    <div className={loginStyle['input-container-common']}>
                      <img src={Calender_icon} alt="Calender_icon" />
                      <DatePicker
                        allowClear={false}
                        suffixIcon={false}
                        placeholder={configuration?.userDataCollection?.dateOfBirth?.text || 'Date of Birth'}
                        inputReadOnly={true}
                        onChange={(e) => {
                          if (e) {
                            const utcDate = new Date(
                              Date.UTC(
                                e.year(),
                                e.month(),
                                e.date(),
                                e.hour(),
                                e.minute(),
                                e.second(),
                                e.millisecond(),
                              ),
                            ).toISOString();
                            data.dateOfBirth = utcDate;
                            setData({ ...data });
                          }
                        }}
                        onKeyDown={keyDownHandler}
                        value={data?.dateOfBirth ? dayjs(data?.dateOfBirth) : ''}
                        disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                      />
                    </div>
                  )}
                  {toggleData?.secondaryFields?.enabled && toggleData?.secondaryFields?.gender?.isRequired && (
                    <div className={loginStyle['dropdown-container']}>
                      <img src={Gender_icon} alt="Gender_icon" />
                      <CustomDropDownForSelect
                        placeholder={configuration?.userDataCollection?.gender?.text || 'Gender'}
                        options={{ array: ['MALE', 'FEMALE', 'OTHERS'] }}
                        customStyleForOptions={{ fontFamily: 'Montserrat' }}
                        customStyle={{
                          border: '1px solid #6D6D6D',
                          fontFamily: 'Montserrat',
                          height: '48px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                        dropdownStyle={{ zIndex: 1 }}
                        hoverColor="#6D6D6D"
                        onKeyDown={keyDownHandler}
                        defaultValueForSelectOption={data?.gender}
                        // value = {data?.gender}
                        onChangeForSelect={(e) => {
                          data.gender = e;
                          setData({ ...data });
                        }}
                      />
                    </div>
                  )}

                  <div className={loginStyle['btn-margin']}>
                    <button
                      className={clsx(loginStyle['btn-style'], loginStyle['otp-btn'])}
                      style={{
                        ...configuration?.userDataCollection?.buttonText,
                        opacity: btnDisabled ? '0.5' : '1',
                        cursor: btnDisabled ? 'not-allowed' : 'pointer',
                      }}
                      disabled={btnDisabled || loading}
                      onClick={() => {
                        updateuserdetails();
                      }}
                    >
                      {loading ? <CTALoader color={'#000000'} /> : data?.buttonText?.text || 'UPDATE PROFILE'}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
