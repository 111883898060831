import React from "react";
import style from "./CommonModal.module.scss";
import { Modal } from "antd";
import { ReactComponent as CrossIcon } from "../../Components/assets/crossIcon.svg";

export default function CommonModal({
  children,
  customStyle,
  isModalVisible,
  visible,
  title,
  width,
  footer,
  centered,
}) {
  return (
    <Modal
      className={style["container"]}
      closable={true}
      centered={centered}
      style={customStyle || {}}
      open={visible}
      onCancel={isModalVisible}
      footer={footer || false}
      title={title}
      width={width}
      closeIcon={<CrossIcon />}
    >
      {children}
    </Modal>
  );
}
