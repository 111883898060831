import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;


export const fetchUsers = async () => {
    try{
        const response = await axios.get(`${API_BASE_URL}/subscription/user-lincense`)
        return response.data.data;
    }catch(error){
        console.error('Error fetching users:', error);
        throw error;
    }
}

export const getSubscription = async () => {
    try{
        const response = await axios.get(`${API_BASE_URL}/authorization/list-user-subscriptions`)
        return response.data.active;
    }catch(error){
        console.error('Error fetching users:', error);
        throw error;
    }
}



export const creatUser = async (userData) => {
    try{
        const response = await axios.post(`${API_BASE_URL}/subscription/assign-license`,userData)
        return response;
    }catch(error){
        console.error('Error fetching users:', error);
        throw error
    }
}

export const updateUser = async (userId, userData) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/subscription/user/${userId}`, userData);
        return response.data; 
    } catch (error) {
        console.error('Error updating user:', error);
        throw error; 
    }
};


export const deleteUser = async (userId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/subscription/user/${userId}`);
        return response.data; 
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error; 
    }
};



