import React from 'react';

const BackArrow = ({color, handleClick}) => {
    console.log(color, 'sdfgjhksdfgksdfgjkjhskdf');
    
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>handleClick()}>
      <path
        d="M10.2953 19.7165C10.3888 19.8087 10.4997 19.8816 10.6214 19.9309C10.7431 19.9803 10.8734 20.0052 11.0047 20.0042C11.136 20.0033 11.2659 19.9764 11.3869 19.9253C11.5079 19.8741 11.6176 19.7996 11.7098 19.706C11.802 19.6125 11.8748 19.5017 11.9242 19.3799C11.9735 19.2582 11.9985 19.1279 11.9975 18.9966C11.9965 18.8653 11.9697 18.7354 11.9185 18.6144C11.8673 18.4934 11.7928 18.3837 11.6993 18.2915L6.32928 13.0015H19.9993C20.2645 13.0015 20.5188 12.8962 20.7064 12.7086C20.8939 12.5211 20.9993 12.2667 20.9993 12.0015C20.9993 11.7363 20.8939 11.482 20.7064 11.2944C20.5188 11.1069 20.2645 11.0015 19.9993 11.0015H6.33628L11.7003 5.71453C11.8842 5.52731 11.9872 5.27527 11.987 5.01281C11.9867 4.75034 11.8833 4.49848 11.6991 4.31158C11.5148 4.12468 11.2644 4.01772 11.002 4.01378C10.7395 4.00985 10.4861 4.10924 10.2963 4.29053L3.37228 11.1125C3.25436 11.2288 3.16073 11.3674 3.09682 11.5202C3.03291 11.673 3 11.8369 3 12.0025C3 12.1681 3.03291 12.3321 3.09682 12.4849C3.16073 12.6377 3.25436 12.7762 3.37228 12.8925L10.2963 19.7155L10.2953 19.7165Z"
        fill={color || "#000000"}
      />
    </svg>
  );
};

export default BackArrow;
