import React, { useEffect, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import Eye_icon from '../assets/loginsettings/eye_icon.svg';
import EyeClose_icon from '../assets/loginsettings/eyeClose_icon.svg';
import Key_icon from '../assets/loginsettings/key_icon.svg';
 import TickWhite_icon from '../assets/loginsettings/tickWhite_icon.svg';
import TickGreen_icon from '../assets/loginsettings/tickGreen_icon.svg';
import { Checkbox, Input } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
import { checkSuccessCode, slientLoginHandlerForMobile } from '../../utills/validator';
import axios from 'axios';
import { handleAuthCodeGeneration } from '../../utills/common';
import { CSCSendEvent, clickAction, eventLocation, eventType } from '../../analytics';
import { Footer } from './Footer';
import CTALoader from '../../utills/ctaLoader';
import BackArrow from './BackArrow';
export function SetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector((state) => state.newClientConfig.setPassword);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const userDetails = useSelector((state) => state.userDetails);
  const [password, setPassword] = useState({
    setPasswordVisible: false,
    confirmPasswordVisible: false,
    setPassword: '',
    confirmPassword: '',
  });
  const [passwordConfig, setPasswordConfig] = useState({
    minLength: false,
    MixCase: false,
    alphaNumeric: false,
    specialChar: false,
  });
  const [enableCheckbox, setEnableCheckbox] = useState({
    enableOffers: false,
    enableNotifications: false,
  });
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const clientId = queryParams.get('clientId');
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const [ctaLoading, setCTALoading] = useState(false);

  useEffect(() => {
    setPasswordConfig({
      minLength: false,
      MixCase: !toggleData?.passwordConfig?.MixCase,
      alphaNumeric: !toggleData?.passwordConfig?.alphaNumeric,
      specialChar: !toggleData?.passwordConfig?.specialChar,
    });
  }, [toggleData?.passwordConfig]);

  function ValidatePassword(password) {
    const defaultValues = {
      minLength: true,
      alphaNumeric: true,
      MixCase: true,
      specialChar: true,
    };
    if (password.length < toggleData?.passwordConfig?.minLength) {
      defaultValues.minLength = false;
    }
    if (toggleData?.passwordConfig?.alphaNumeric) {
      if (!password.match(/[a-zA-Z]/) || !password.match(/[0-9]/)) defaultValues.alphaNumeric = false;
    }
    if (toggleData?.passwordConfig?.MixCase) {
      if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) defaultValues.MixCase = false;
    }
    if (toggleData?.passwordConfig?.specialChar) {
      if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) defaultValues.specialChar = false;
    }
    setPasswordConfig(defaultValues);
    return true;
  }
  const checkinput = (value) => {
    if (ValidatePassword(value)) {
      return setPassword({ ...password, setPassword: value });
    }
  };

  const handleRegister = async () => {
    setCTALoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/register`,
        data: {
          name: userDetails?.fullName,
          gender: userDetails?.gender,
          dateOfBirth: userDetails?.dateOfBirth,
          phone: userDetails?.phone,
          countryCode: userDetails?.dail_code,
          email: userDetails?.email,
          clientGroupId: clientGroupId,
          primary: userDetails?.primary,
          clientId: commanUrlValue.clientId,
          loginType: 'password',
          pass: password.setPassword,
          cnfPass: password.setPassword,
          enableOffers: enableCheckbox?.enableOffers,
          enableNotifications: enableCheckbox?.enableNotifications,
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
          ...(userDetails?.secondaryEmail && { secondaryEmail: userDetails?.secondaryEmail }),
          ...(userDetails?.secondaryPhone && { secondaryPhone: userDetails?.secondaryPhone }),
        },
      });
      localStorage.setItem('code', response.data.authorizationCode);
      slientLoginHandlerForMobile(
        JSON.stringify({
          message: 'SLIENT_USER_LOGIN',
          authToken: localStorage.getItem('code'),
          statusCode: response?.status,
        }),
      );
      if (!checkSuccessCode(response)) {
        setCTALoading(false);
        return antdMessageWarning(
          'We encountered an issue while checking your registration status. Please try again later.',
        );
      } else {
        setCTALoading(false);
        const ssoSignupClickObject = {
          eventType: eventType.CLICK,
          eventLocation: eventLocation.SSO_SIGNUP,
          clickAction: clickAction.SSO_SIGNUP_SET_PASSWORD_BTN,
          clientId: commanUrlValue?.clientId,
          anonId: commanUrlValue?.anonId,
          paywallId: commanUrlValue?.paywallId,
          url: decodeURIComponent(window.location.href || ''),
          segmentId: commanUrlValue?.segmentId,
          journeyId: commanUrlValue?.journeyId,
        };
        CSCSendEvent(ssoSignupClickObject);
        // alert(commanUrlValue.SHOWPOP);
        handleAuthCodeGeneration(
          response?.data?.authorizationCode,
          redirectUrl,
          commanUrlValue,
          clientId,
          navigate,
          userDetails,
          commanUrlValue.SHOWPOP,
          commanUrlValue.popUpId,
          commanUrlValue.siteUrl,
          commanUrlValue.skip,
        );
      }
    } catch (error) {
      slientLoginHandlerForMobile(
        JSON.stringify({
          message: 'LOGIN_FAILED',
          errorMessage: error?.response?.data?.message,
          statusCode: error?.response?.status,
        }),
      );
      return antdMessageError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : 'We’re unable to complete your registration at the moment. Please try again later.',
      );
    }
  };

  useEffect(() => {
    const ssoSignupSetPasswordViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_SIGNUP_SET_PASSWORD,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoSignupSetPasswordViewObject);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/login?${queryParams.toString()}`);
    }
  }, [navigate]);
  const disableBtn =
    password?.setPassword !== '' &&
    password?.setPassword === password?.confirmPassword &&
    passwordConfig?.minLength &&
    passwordConfig?.alphaNumeric &&
    passwordConfig?.MixCase &&
    passwordConfig?.specialChar;
  return (
    <div className={loginStyle['main-container']}>
      <div style={{ flexGrow: 1 }}>
        <div className={loginStyle['header-logo']}>
          <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
        </div>
        <div className={loginStyle['container']}>
          <div className={loginStyle['child-container']}>
            <div className={loginStyle['cross_back_icon']}>
              <BackArrow
                color={data?.formTitle?.color}
                handleClick={()=>navigate(`${location?.state}?${queryParams.toString()}`)}
              />
            </div>
            <div className={loginStyle['heading-container']}>
              <div>
                <div className={loginStyle['heading-text']} style={data?.formTitle}>
                  {data?.formTitle?.text || 'Create new account'}
                </div>
                <div className={loginStyle['sub-text']} style={data?.subtitle}>
                  {data?.subtitle?.text || 'Please enter your valid details below'}
                </div>
              </div>
            </div>
            <div className={loginStyle['setpasswprd-form-container']}>
              <div className={loginStyle['input-container-common']}>
                <img src={Key_icon} alt="Key_icon" />
                <Input
                  placeholder={data?.setPassword?.text || 'Set Password'}
                  style={data?.setPassword}
                  type={password.setPasswordVisible ? 'text' : 'password'}
                  onChange={(e) => checkinput(e.target.value)}
                />
                <img
                  src={password.setPasswordVisible ? Eye_icon : EyeClose_icon}
                  alt="EyeClose_icon"
                  onClick={() => {
                    setPassword({
                      ...password,
                      setPasswordVisible: !password.setPasswordVisible,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={loginStyle['input-container-common']}>
                <img src={Key_icon} alt="Key_icon" />
                <Input
                  placeholder={data?.confirmPassword?.text || 'Confirm Password'}
                  style={data?.confirmPassword}
                  type={password.confirmPasswordVisible ? 'text' : 'password'}
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      confirmPassword: e.target.value,
                    });
                  }}
                />
                <img
                  src={password.confirmPasswordVisible ? Eye_icon : EyeClose_icon}
                  alt="Eye_icon"
                  onClick={() => {
                    setPassword({
                      ...password,
                      confirmPasswordVisible: !password.confirmPasswordVisible,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {password?.setPassword !== password?.confirmPassword && (
                <div className={loginStyle['error-message']}>Ensure both passwords are the same.</div>
              )}
              <div className={loginStyle['validation-container']}>
                {(toggleData?.passwordConfig?.minLength > 0 ||
                  toggleData?.passwordConfig?.alphaNumeric ||
                  toggleData?.passwordConfig?.MixCase ||
                  toggleData?.passwordConfig?.specialChar) && (
                  <>
                    <div>Password should contain the following parameters:</div>
                    {toggleData?.passwordConfig?.minLength > 0 && (
                      <div className={loginStyle['validation_text']}>
                        <img src={passwordConfig.minLength ? TickGreen_icon : TickWhite_icon} alt="TickGreen_icon" />
                        <div>Include at least {toggleData?.passwordConfig?.minLength} characters</div>
                      </div>
                    )}
                    {toggleData?.passwordConfig?.alphaNumeric && (
                      <div className={loginStyle['validation_text']}>
                        <img src={passwordConfig.alphaNumeric ? TickGreen_icon : TickWhite_icon} alt="TickGreen_icon" />
                        <div>Include Alpha numerical</div>
                      </div>
                    )}
                    {toggleData?.passwordConfig?.MixCase && (
                      <div className={loginStyle['validation_text']}>
                        <img src={passwordConfig.MixCase ? TickGreen_icon : TickWhite_icon} alt="TickGreen_icon" />
                        <div>Include Mixed case</div>
                      </div>
                    )}
                    {toggleData?.passwordConfig?.specialChar && (
                      <div className={loginStyle['validation_text']}>
                        <img src={passwordConfig.specialChar ? TickGreen_icon : TickWhite_icon} alt="TickWhite_icon" />
                        <div>Include Special characters (for example, !, $, #, %).</div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {toggleData?.optins?.[0]?.isRequired && (
                <div className={loginStyle['some-checks']}>
                  <Checkbox
                    checked={enableCheckbox.enableOffers}
                    onChange={(e) => {
                      setEnableCheckbox({ ...enableCheckbox, enableOffers: e.target.checked });
                    }}
                  />
                  <div style={data?.OptIn1}>
                    {data?.OptIn1?.text || 'Receive offers, receipts, news & event updates'}
                  </div>
                </div>
              )}
              {toggleData?.optins?.[1]?.isRequired && (
                <div className={loginStyle['some-checks']}>
                  <Checkbox
                    checked={enableCheckbox.enableNotifications}
                    onChange={(e) => {
                      setEnableCheckbox({ ...enableCheckbox, enableNotifications: e.target.checked });
                    }}
                  />
                  <div style={data?.OptIn2}>
                    {data?.OptIn2?.text || 'Get notified on Whatsapp, Get notified on Whatsapp'}
                  </div>
                </div>
              )}
            </div>
            <button
              className={loginStyle['btn-style']}
              style={{
                ...data?.buttonText,
                opacity: !disableBtn ? '0.5' : '1',
                cursor: !disableBtn ? 'not-allowed' : 'pointer',
              }}
              disabled={ctaLoading ? true : !disableBtn}
              onClick={() => handleRegister()}
            >
              {ctaLoading ? <CTALoader /> : data?.buttonText?.text || 'Submit'}
            </button>
            {/* <div className={loginStyle['already-account']} style={data?.loginLinkText}>
            {data?.loginLinkText?.text || 'Already have an account'}&nbsp;&nbsp;
            <span onClick={() => navigate(`/login?${queryParams.toString()}`)} className={loginStyle['link']}>
              Log In
            </span>
          </div> */}

            <div className={loginStyle['by-continue']} style={data?.TandCtext}>
              {data?.TandCtext?.text || 'By continuing, you agree to our'}{' '}
              <a className={loginStyle['by-noHover']} href={toggleData?.redirects?.tnc} rel="noopener noreferrer">
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                className={loginStyle['by-noHover']}
                href={toggleData?.redirects?.privPolicy}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer termsAndConditions={toggleData?.redirects?.tnc} privacyAndPolicy={toggleData?.redirects?.privPolicy} />
    </div>
  );
}
