import React from 'react';

import loderStyle from './ctaLoader.module.css';

function CTALoader({color}) {
  return (
    <div className={loderStyle.loader}>
      <div style={{ backgroundColor: color }}></div>
      <div style={{ backgroundColor: color }}></div>
      <div style={{ backgroundColor: color }}></div>
    </div>
  );
}

export default CTALoader;
