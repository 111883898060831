import React from "react";
import style from "./index.module.scss"

function Loader({ message }) {
  return (
    <>
      <div className={style["loader-container"]}>
        <div id={style["loader"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={style["loader-text"]}>
        {message}
      </div>
    </>
  );
}

export default Loader;
