export const TableColumns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      width: 250,
      align: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left', 
    },
    {
      title: 'Plan Name',
      dataIndex: 'planName',
      key: 'planName',
      align: 'left', 
    },
    {
      title: 'Sub. Added On',
      dataIndex: 'subAddedOn',
      key: 'subAddedOn',
      align: 'left', 
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
    }
  ];