import React, { useEffect, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import Eye_icon from '../assets/loginsettings/eye_icon.svg';
import EyeClose_icon from '../assets/loginsettings/eyeClose_icon.svg';
import Key_icon from '../assets/loginsettings/key_icon.svg';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import { Checkbox, Input } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { checkSuccessCode, slientLoginHandlerForMobile } from '../../utills/validator';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
import { handleAuthCodeGeneration } from '../../utills/common';
import { CSCSendEvent, clickAction, eventLocation, eventType } from '../../analytics';
import CTALoader from '../../utills/ctaLoader';
import { Footer } from './Footer';
import clsx from 'clsx';
import BackArrow from './BackArrow';

export function LoginEmail() {
  const queryParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const navigate = useNavigate();
  const data = useSelector((state) => state.newClientConfig.loginEmail);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const userDetails = useSelector((state) => state.userDetails);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    passwordVisible: false,
    setPassword: '',
    btnDisabled: true,
  });

  const login = async () => {
    try {
      const getDetailsResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/login`,
        data: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          ...(userDetails?.phone && {
            phone: userDetails?.phone,
            loginType: 'password',
          }),
          ...(userDetails?.email && {
            email: userDetails?.email,
            loginType: 'password',
          }),
          password: password?.setPassword,
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
          transactionId: commanUrlValue.transactionId,
        },
      });
      if (checkSuccessCode(getDetailsResponse)) {
        const ssoLoginPasswordBtnClickObject = {
          eventType: eventType.CLICK,
          eventLocation: eventLocation.SSO_LOGIN,
          clickAction: clickAction.SSO_LOGIN_PASSWORD_BTN,
          clientId: commanUrlValue?.clientId,
          anonId: commanUrlValue?.anonId,
          paywallId: commanUrlValue?.paywallId,
          url: decodeURIComponent(window.location.href || ''),
          segmentId: commanUrlValue?.segmentId,
          journeyId: commanUrlValue?.journeyId,
        };
        CSCSendEvent(ssoLoginPasswordBtnClickObject);
        localStorage.setItem('code', getDetailsResponse?.data?.authorizationCode);
        slientLoginHandlerForMobile(
          JSON.stringify({
            message: 'SLIENT_USER_LOGIN',
            authToken: localStorage.getItem('code'),
            statusCode: getDetailsResponse?.status.toString(),
          }),
        );
        handleAuthCodeGeneration(
          getDetailsResponse?.data?.authorizationCode,
          commanUrlValue.redirectUrl,
          commanUrlValue,
          commanUrlValue.clientId,
          navigate,
        );
      }
    } catch (error) {
      slientLoginHandlerForMobile(
        JSON.stringify({
          message: 'LOGIN_FAILED',
          errorMessage: error?.response?.data?.message,
          statusCode: error?.response?.status,
        }),
      );
      antdMessageWarning(
        error?.response?.data?.message || 'The password doesn’t seem right. Please check and try again.',
      );
    }
  };

  useEffect(() => {
    const ssoLoginPasswordViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_LOGIN_PASSWORD,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginPasswordViewObject);
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/login`,
        data: {
          ...(userDetails?.phone && { phone: userDetails?.phone, countryCode: '+91' }),
          ...(userDetails?.email && { email: userDetails?.email }),
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          loginType: 'otp',
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
          transactionId: commanUrlValue.transactionId,
        },
      });
      if (checkSuccessCode(response)) {
        setLoading(false);
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/login', email: userDetails?.email, phone: userDetails?.phone },
        });
      }
    } catch (error) {
      setLoading(false);
      return antdMessageError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/login?${queryParams.toString()}`);
    }
  }, [navigate]);
  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!password.btnDisabled) {
        login();
      }
    }
  };

  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('email');
    queryParams.delete('phone');

    navigate(`/login?${queryParams.toString()}`);
  };

  return (
    <div className={loginStyle['main-container']}>
      <div style={{ flexGrow: 1 }}>
        <div className={loginStyle['header-logo']}>
          <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
        </div>
        <div className={loginStyle['container']}>
          <div className={loginStyle['child-container']}>
            <div className={loginStyle['backArrow_icon']}> 
              <BackArrow color={data?.formTitle?.color} handleClick={handleBackClick}/>
            </div>
            <div className={loginStyle['heading-container']}>
              <div>
                <div className={loginStyle['heading-text']} style={data?.loginFormTitle}>
                  {data?.loginFormTitle?.text || 'Welcome back'}
                </div>
                <div className={loginStyle['sub-text']} style={data?.signupLinkText}>
                  {/* {data?.signupLinkText?.text || 'Don’t have an account? '}&nbsp;&nbsp; */}
                </div>
              </div>
            </div>
            <div className={loginStyle['form-container']}>
              {userDetails?.loginType === 'EMAIL' && (
                <div className={loginStyle['input-container-common']}>
                  <img src={Email_icon} alt="Email_icon" />
                  <Input
                    placeholder={data?.secondaryLoginInput?.text || 'Email'}
                    value={userDetails?.email}
                    disabled={userDetails?.email ? true : false}
                    style={{
                      ...data?.secondaryLoginInput,
                      backgroundColor: userDetails?.email ? '#EBEBEB' : '',
                    }}
                  />
                </div>
              )}
              {userDetails?.loginType === 'PHONE' && (
                <div className={loginStyle['input-container-common']}>
                  <img src={Mobile_icon} alt="Mobile_icon" />
                  <Input
                    placeholder="+91435647545345"
                    value={`+91${userDetails?.phone}`}
                    disabled={userDetails?.phone ? true : false}
                    style={{
                      backgroundColor: userDetails?.phone ? '#EBEBEB' : '',
                    }}
                  />
                </div>
              )}
              <div className={loginStyle['input-container-common']}>
                <img src={Key_icon} alt="Key_icon" />
                <Input
                  placeholder={data?.enterPassword?.text || 'Enter Password'}
                  style={data?.enterPassword}
                  type={password.passwordVisible ? 'text' : 'password'}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      setPassword: e.target.value,
                      btnDisabled: false,
                    })
                  }
                  onKeyDown={keyDownHandler}
                />
                <img
                  src={password?.passwordVisible ? Eye_icon : EyeClose_icon}
                  alt="Eye_icon"
                  onClick={() =>
                    setPassword({
                      ...password,
                      passwordVisible: !password?.passwordVisible,
                    })
                  }
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={loginStyle['toggle-box']} style={{ marginTop: '6px' }}>
                <div className={loginStyle['common-flex-box']}>
                  {/* <Checkbox />
                <div className={loginStyle['text-style']} style={data?.rememberMeText}>
                  {data?.rememberMeText?.text || 'Remember me'}
                </div> */}
                </div>
                <div
                  className={loginStyle['forgot-password']}
                  style={{ ...data?.forgotPasswordText, cursor: 'pointer' }}
                  onClick={() => navigate(`/forgetpassword?${queryParams.toString()}`)}
                >
                  {data?.forgotPasswordText?.text || 'Forgot Password ?'}
                </div>
              </div>
            </div>
            <button
              className={loginStyle['btn-style']}
              disabled={password?.btnDisabled}
              style={{
                ...data?.buttonText,
                opacity: password?.btnDisabled ? '0.5' : '1',
                cursor: password?.btnDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => login()}
            >
              {data?.buttonText?.text || 'Login'}
            </button>
            {toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password && (
              <button
                className={clsx(loginStyle['btn-style'], loginStyle['btn-margin'], loginStyle['otp-btn'])}
                style={data?.otpButtonText}
                disabled={loading}
                onClick={() => {
                  handleLogin();
                }}
              >
                {loading ? <CTALoader color={'#000000'} /> : data?.otpButtonText?.text || 'Continue with One-time Passcode (OTP)'}
              </button>
            )}
            <div className={loginStyle['by-continue']} style={data?.TandCtext}>
              {data?.TandCtext?.text || 'By continuing, you agree to our'}{' '}
              <a className={loginStyle['by-noHover']} href={toggleData?.redirects?.tnc} rel="noopener noreferrer">
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                className={loginStyle['by-noHover']}
                href={toggleData?.redirects?.privPolicy}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer termsAndConditions={toggleData?.redirects?.tnc} privacyAndPolicy={toggleData?.redirects?.privPolicy} />
    </div>
  );
}
